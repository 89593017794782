import { createClient } from "contentful"
import PrincipalImageDTO from "./PrincipalImageDTO"
import TitledParagraphDTO from "./TitledParagraphDTO"

export const contentful = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

export const getHeaderFields = async (id) =>
  await contentful
    .getEntry(id)
    .then(PrincipalImageDTO)
    .catch((err) => console.log(err))

export const getTitledParagraphs = async (id) =>
  await contentful
    .getEntry(id)
    .then(TitledParagraphDTO)
    .catch((err) => console.log(err))

export const getTitleText = async (id) =>
  await contentful
    .getEntry(id)
    .then((entry) => ({ text: entry.fields.text }))
    .catch((err) => console.log(err))

export const getAssetUrl = async (id) => {
  const response = await contentful
    .getAsset(id)
    .catch((err) => console.log(err))
  return formatUrl(response.fields.file.url)
}

export const formatUrl = (url) => {
  return `https:${url}`
}

export default contentful
