import { Box, Grid } from "@mui/material"
import Button from "../components/buttons/Button"
import styled from "styled-components"
import Bubble from "../components/Bubble"
import { isMobile } from "react-device-detect"
import Footer from "components/Footer"
import { useHistory } from "react-router-dom"
import { useCallback } from "react"
import { useState } from "react"
import { useEffect } from "react"
import {
  getAssetUrl,
  getHeaderFields,
  getTitledParagraphs,
  getTitleText,
} from "lib/contentful"
import {
  HOME_PAGE_RIGHT_PANEL_IMAGE,
  HOME_PAGE_HEADER,
  HOME_PAGE_MIDDLE_PARAGRAPHS,
  HOME_PAGE_VALUE_SECTION_TITLE,
} from "lib/ids"
// import { contentful } from "lib/contentful"

const HomePage = () => {
  // const [mainVideoUrl, setMainVideoUrl] = useState()
  const history = useHistory()
  const handleClickToBoutique = () => history.push("/boutique")

  const [rightPanelImageUrl, setRightPanelImageUrl] = useState()
  const fetchRightPanelImageUrl = useCallback(
    async () =>
      setRightPanelImageUrl(await getAssetUrl(HOME_PAGE_RIGHT_PANEL_IMAGE)),
    []
  )
  useEffect(() => fetchRightPanelImageUrl(), [fetchRightPanelImageUrl])

  const [headerContent, setHeaderContent] = useState({})
  const fetchHeaderContent = useCallback(
    async () => setHeaderContent(await getHeaderFields(HOME_PAGE_HEADER)),
    []
  )
  useEffect(() => fetchHeaderContent(), [fetchHeaderContent])

  const [middleSectionParagraphs, setMiddleSectionParagraphs] = useState({})
  const fetchMiddleSectionParagraphs = useCallback(
    async () =>
      setMiddleSectionParagraphs(
        await getTitledParagraphs(HOME_PAGE_MIDDLE_PARAGRAPHS)
      ),
    []
  )
  useEffect(
    () => fetchMiddleSectionParagraphs(),
    [fetchMiddleSectionParagraphs]
  )

  const [valueSectionTitle, setValueSectionTitle] = useState()
  const fetchValueSectionTitle = useCallback(
    async () =>
      setValueSectionTitle(await getTitleText(HOME_PAGE_VALUE_SECTION_TITLE)),
    []
  )
  useEffect(() => fetchValueSectionTitle(), [fetchValueSectionTitle])

  // const fetchMainVideoUrl = useCallback((isMobile) => {
  //   if (isMobile) return "./vid/vertical.mp4"
  //   else {
  //     return contentful
  //       .getAsset("7ee8kpolayv3xbF0BQJdvW")
  //       .then((asset) => setMainVideoUrl(`https:${asset.fields.file.url}`))
  //       .catch((err) => console.log(err))
  //   }
  // }, [])

  // useEffect(() => {
  //   fetchMainVideoUrl(isMobile)
  // }, [fetchMainVideoUrl])

  const BubblesContent = [
    {
      title: "RESPECT DE LA NATURE",
      subtitle:
        "J'aime travailler à partir de matériaux naturels. Que ce soit les bâtons de bois flotté que je sélectionne au bord des rives, les bois d'orignaux qui sont trouvés en forêt ou récupérés de la chasse, le coton naturel compostable que je privilégie ou mes cordes de couleurs recyclées.",
    },
    {
      title: "CONNEXION",
      subtitle:
        "Je crois que l'art offre un moyen unique de s'exprimer, de communiquer entre nous et de se comprendre. Et lorsqu'on est en connexion avec soi-même, on est aussi plus facilement en connexion avec les autres.",
    },
    {
      title: "SIMPLICITÉ",
      subtitle:
        "Je suis une grande fervente du « acheter moins, acheter mieux ». Parce que vivre dans une maison qui prend vie grâce à quelques trésors choisis avec amour, ça n'a pas de prix.",
    },
  ]

  return (
    <>
      <div>
        <VideoBackground
          src={headerContent.imageUrl}
          alt="./img/main_alt.png"
          autoPlay
          playsInline
          loop
          muted
        />
      </div>

      <Box
        px={{ xs: 6, sm: 8, md: 16 }}
        py={{ xs: 36, sm: 45 }}
        sx={{ position: "relative", zIndex: 1 }}
      >
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Title>{headerContent.title}</Title>
          </Grid>
        </Grid>
      </Box>

      <CustomContainer>
        <Box px={6} pt={isMobile ? 0 : 30} pb={4}>
          <Grid container justifyContent="space-around">
            <Grid item md={5} sm={12}>
              <MiddleSectionTitle>
                {middleSectionParagraphs.title}
              </MiddleSectionTitle>
              {middleSectionParagraphs.paragraphs?.map((paragraph) => (
                <MiddleSectionParagraph>{paragraph}</MiddleSectionParagraph>
              ))}
            </Grid>
            <Grid item md={6} sm={12} pt={{ xs: isMobile ? 8 : 0 }}>
              <Container>
                <RightPanel
                  src={rightPanelImageUrl}
                  alt="Right Panel Middle Image"
                />
              </Container>
            </Grid>
          </Grid>
        </Box>
      </CustomContainer>

      <Box px={{ xs: 0, sm: 8, md: 18 }} pb={8}>
        <Grid container p={2} pl={4}>
          <Grid item>
            <MiddleSectionTitle>{valueSectionTitle?.text}</MiddleSectionTitle>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {BubblesContent.map((element, index) => (
            <Grid item key={index} xs={10} md={4}>
              <Bubble title={element.title} content={element.subtitle} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box px={isMobile ? 3 : 10}>
        <Grid container>
          <Grid item>
            <Box>
              <MiddleSectionTitle>MES CRÉATIONS DU MOMENT</MiddleSectionTitle>
            </Box>
            <Box py={1}>
              <MiddleSectionParagraph>
                Il m'arrive de créer uniquement selon mon inspiration. D'avoir
                la pulsion de concrétiser une idée qui grossit en moi (il y en a
                tout le temps!).
              </MiddleSectionParagraph>
            </Box>
            <MiddleSectionParagraph>
              Et j'ai également quelques modèles de macramés muraux et
              jardinières coup de coeur que je garde maintenant disponibles en
              permanence sur ma boutique.
            </MiddleSectionParagraph>
            <Box py={6}>
              <Button onClick={handleClickToBoutique}>Voir la boutique</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  )
}

export default HomePage

const CustomContainer = styled.div`
  /* iphone 3 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) {
  }

  /* iphone 4 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) {
  }

  /* iphone 5 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  }

  /* iphone 6, 6s, 7, 8 */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  }

  /* iphone 6+, 6s+, 7+, 8+ */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  }

  /* iphone X , XS, 11 Pro, 12 Mini */
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    padding-top: 10rem;
  }

  /* iphone 12, 12 Pro */
  @media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    padding-top: 11rem;
    padding-bottom: 0rem;
  }

  /* iphone XR, 11 */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    padding-top: 14rem;
    padding-bottom: 0rem;
  }

  /* iphone XS Max, 11 Pro Max */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  }

  /* iphone 12 Pro Max */
  @media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  }

  /* google pixel 4 xl */
  @media only screen and (device-width: 412px) and (device-height: 869px) and (-webkit-device-pixel-ratio: 3.5) {
    padding-top: 14rem;
    padding-bottom: 0rem;
  }

  /* google pixel 5 */
  @media only screen and (device-width: 393px) and (device-height: 851px) and (-webkit-device-pixel-ratio: 2.75) {
    padding-top: 14rem;
    padding-bottom: 0rem;
  }

  /* samsung S20 Ultra */
  @media only screen and (device-width: 412px) and (device-height: 915px) and (-webkit-device-pixel-ratio: 3.5) {
    padding-top: 16rem;
    padding-bottom: 0rem;
  }

  /* samsung S8+ */
  @media only screen and (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }

  /* samsung S8 */
  @media only screen and (device-width: 360px) and (device-height: 740px) {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }

  /* samsung A51/A71 */
  @media only screen and (device-width: 412px) and (device-height: 914px) {
    padding-top: 18rem;
    padding-bottom: 0rem;
  }

  /* iPad air */
  @media only screen and (device-width: 820px) and (device-height: 1180px) {
    padding-top: 22rem;
    padding-bottom: 0rem;
  }

  /* iPad mini */
  @media only screen and (device-width: 768px) and (device-height: 1024px) {
    padding-top: 15rem;
    padding-bottom: 0rem;
  }

  /* Surface Pro 7 */
  @media only screen and (device-width: 912px) and (device-height: 1368px) {
    padding-top: 32rem;
    padding-bottom: 0rem;
  }

  /* iPad Pro */
  @media only screen and (device-width: 1024px) and (device-height: 1366px) {
    padding-top: 32rem;
    padding-bottom: 0rem;
  }

  /* Galaxy Tab S4 */
  @media only screen and (device-width: 712px) and (device-height: 1138px) {
    padding-top: 26rem;
    padding-bottom: 0rem;
  }
`

const VideoBackground = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  opacity: 0.4;

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ratio: 16/9) {
    height: 100vh;
    padding: 0;
    object-fit: fill;
  }
`

const Title = styled.h1`
  font-size: 50px;
  color: #9f2e0e;
  text-shadow: 4px 4px 4px #00000040;
  z-index: 1;

  @media (max-width: 1300px) {
    font-size: 50px;
  }
  @media (max-width: 1000px) {
    font-size: 40px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
`

const Container = styled.div`
  max-width: 100vw;
`
const RightPanel = styled.img`
  max-width: 100vw;
  max-height: 70vh;
  height: 80vh;

  @media (max-aspect-ratio: 16/9) {
    height: auto;
  }
`

const MiddleSectionTitle = styled.p`
  font-size: 36px;
  color: #9f2e0e;

  @media (max-width: 800px) {
    font-size: 22px;
  }
  @media (min-aspect-ratio: 16/9) {
    padding-top: 6rem;
  }
`

const MiddleSectionParagraph = styled.p`
  font-size: 25px;
  line-height: 30px;
  color: #0000000;
  font-family: Barlow;

  @media (max-width: 1300px) {
    font-size: 20px;
  }
  @media (max-width: 1000px) {
    font-size: 18px;
  }
`
