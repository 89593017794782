export const APP_LOGO = "6aBS4SJN98k2m615IRBeXv"

export const HOME_PAGE_MAIN_VIDEO_ID = "4EnqZOrGI463O15EA6SwEf"
export const HOME_PAGE_MAIN_VIDEO_MOBILE_ID = "79anr2pLKAcRdUGdjPV93B"
export const HOME_PAGE_RIGHT_PANEL_IMAGE = "7qd3TXxBB0FsTrSmyHimrr"
export const HOME_PAGE_HEADER = "5j8FQOr22hiIrXWzGuOmBv"
export const HOME_PAGE_MIDDLE_PARAGRAPHS = "1bPx4dKIWqhC2iTZXYALMs"
export const HOME_PAGE_VALUE_SECTION_TITLE = "2ntfnBfrGXuy93DKhMojvI"

export const MY_APPROACH_BOTTOM_IMAGE = "7FqlHTbKdhFTrc7uCU696s"
export const PERSONAL_PIECE_FULL_IMAGE = "31ISBKVAZCgE8svaC7i0aK"
export const PERSONAL_PIECE_FULL_IMAGE_MOBILE = "4ptW8DE5Ab5hvQj7npgLeC"
export const CREATE_TOGETHER_FULL_IMAGE = "604RLXrTcWFvOrBkmIQBMJ"
export const CREATE_TOGETHER_FULL_IMAGE_MOBILE = "27Pdi64DHVXWKkjRNnbOUy"
export const PANACHES_FULL_IMAGE = "kc18cIoO8daEvBvJSOfFb"
export const PANACHES_FULL_IMAGE_MOBILE = "2CHzn3Y4FMK4SkdHhGklBR"
export const CLASSES_FULL_IMAGE = "7Lt0R7lgM5IEBThnkFgoYr"
export const CLASSES_FULL_IMAGE_MOBILE = "61WJu9quWi7fX1zCw84ERE"

export const getProductImages = (productId) => {
  return BOUTIQUE_PRODUCTS[productId]
}

export const getProductFirstImageId = (productId) => {
  return BOUTIQUE_PRODUCTS[productId][0]
}

export const BOUTIQUE_PRODUCTS = {
  prod_LvJjoPzW2xle0n: [
    "6EjGPEmwqOhj5FjjRvGvHG",
    "2KtAcW6TniPBQeBXRzwBCU",
    "7hPrjPubRCw9FEsZT8vKbv",
    "3UhEi9a4pdxwWfCuJ0zumr",
    "14s7bcfCosFY2rFRL03uOM",
    "3NM0Ta7FJmI2YdzDxFpyIJ",
  ], // BOUCLE_OREILLE_BOHO
  prod_Kvg9l6AgNr51bB: [
    "2QZoe3ZPXgxQk7UNiDTmTM",
    "1hoz0LBNx26H5ml25QRkal",
    "7zrEv2B23dChst4BcoD380",
    "63gSScwCdzoWSgIhsVL1WS",
  ], // CORDE_PAQUET_5
  prod_8XO3wpW2ZWlYAz: ["6UhjkBZzXuyRnRByBirGya", "5TPg2Ur6kFy4X69FoQJpVq"], // CORDES_3mm_GROS_ROULEAU
  prod_A12JwredLXlPjn: ["sSG12JdMwCb8eU2epAg55", "1VX40GmsUxQI04ZBK7o0DT"], // CORDES_3mm_100pi
  prod_NqKE50bgZLwdgB: [
    "dsQlJ9SFEdBx60ECA7Jk0",
    "OnNoCUuJ6MQdO1FGAiMsL",
    "5DG6LkCPFaG2wG3MVUfXXU",
  ], // CORDES_3mm_460pi
  prod_0YnEoqPRqOwe7P: ["106pCzoNCOYVv8y6kE6xeA", "79TItKq5LKJGRaXlu422Y8"], // CORDES_4mm_525pi
  prod_4WJvlKpYGxwbYV: ["4iIDHxMnulpSy66fREPKU3", "2tT7far9bhatJsbAekYUlD"], // CORDES_7mm_40pi
  prod_9BAmwJyYz1leXd: [
    "6m2xg8OKXY7KbAFZHSwinc",
    "aKK8pCllfWMzpMBwVVgYx",
    "7sdhkvY1wJZ20ogsvu561u",
    "6UlLHoGwQMnD7EvndSjvCY",
    "4AVqONw1dk0RzxM2KGpdty",
  ], // COURS_MACRAMÉ_INITIATION
  prod_gvRjwOrY3Al4mN: [
    "7fGGJWJztDxsIdiPpSLYar",
    "elZ83IA8mmoYSfshuHJTR",
    "fUuOf4ybJ3Wn10pj9WX4f",
    "4POIUyCzOeUq5ad3BXF4B2",
    "6GwYlG8GKmBs1PfGrsayGn",
    "4qdVshhUNiHS66FKRImZ1B",
    "drXI2a92R3WDKqW0PmyoJ",
  ], // COURS_MACRAMÉ_EXPERT
  prod_ZM8X5nPZEJwpv4: [
    "7fda9IXFFHSMv2sWcgFSd1",
    "2ZA5vcrYtwGGbZHnZ050W2",
    "7dS3Ej5TMKGDELQWVc7qbc",
    "3xTsjSg9iidk1LYWwVMR72",
    "2Xy7vq2tqB3UiNrbZNxJxp",
    "2UDnnvr4zqKG0E5RHpERi3",
    "4tIm6hXtFtmHdrctsPBtf",
    "3WM8n48kIzVLixUuoS68mC",
    "1JkfS7iAz0JJscmorZrV56",
  ], // COURS_MACRAMÉ_AVANCE
  prod_ZM8X5nq4E8opv4: [
    "2ZwS8kjBZPktUlvhCzjLW6",
    "4aPGfrUZepEPpdUoClPLnq",
    "3cCEgm9mjivVgQqF9HLk8k",
  ], // COURS_COLORATION_VEGETALE
  prod_ypbroE9VNmo8n4: ["AMjahDwuzfCRGc9sfempH", "77DaXh6PPtcdOnqAS8Rroc"],
  prod_DWy4oG4Y2j56Jx: [
    "5nzlbDTrDHpZFpEi58QUZ9",
    "50ubk2KrEdRO5v0LJXL1Uy",
    "1QNd1Yzrh4mKNeJuLYVFzd",
    "39mQAo66jpvY8mSJqpv3T0",
  ], // ANN
  prod_DWy4oGEpgjl6Jx: ["3yjZ7h5SlOORcYsYk5LAX7", "4hYJrLX1xdOxBBbffeIwEZ"], // AU_PAYS_DES_DOUCEURS
  prod_Kvg9l61rGR51bB: ["tY49xAtKW1dh3y1v4bfA9"], // ILANA
  prod_8XO3wpM2yNoYAz: [
    "2nG3kBHo0G9TfjNGB7GnJI",
    "4HCgJ4SZPQXUsrEdqqgRZM",
    "6AYAdc3G0dxCfL6JvR0Qaf",
    "6NnqSzQRNi0CM8kyhEDw7z",
    "1m09UV0Y5qCcE8YZdEmeQ7",
  ], // LEILA
  prod_0YnEoqPRALwe7P: [
    "ALL5Mh23K9aVRwkcheAZe",
    "4J4EHx9RYSRPw8mD0675PW",
    "72HGu3QUziPWAZcRGX1Zn4",
    "6IRGnrsBXtJBeinwn6QAHf",
    "1vwpx4VYLBQBgtGvgWWRUj",
  ], // JARDINIERES UYUNI
  prod_Kvg9l61PYn51bB: [
    "3a0Bw7SHjX0ZANG9Wo7VOH",
    "3ZWAVfKjMZ0RA4YMhAFIR5",
    "6PvVOZ6lTAbIqy1iHqtBrG",
    "26jtEKbJtpIwPCDqBbBI9c",
    "S90SYNWwcnSo2mfRraewX",
  ], // MANDALA_BOHO
  prod_7ZAMo1QgA1oNJ4: [
    "4KxDNXKH3ES8WI49VuAFI2",
    "3USG9gaGP9eZsJS1x88ZxV",
    "2kRCZfWG7ksG6sKIZyjl56",
  ], // RHEA
  prod_0egY5eBx39l3Qn: ["2gDPeZLD5pGyb9FGy27WRX"], // ROSE_DELIGHT
  prod_4OANwRGY3kovYL: [
    "2qjw6iT2PDAYOIIxlNRox9",
    "5wVzIRT4qRpo9hCpmcR9QT",
    "1HNVpLe2ge13e1y7L4V73X",
    "foZVQynrwjvHOKSQMgHpR",
    "2fFhC0Z3jowJJtV2120fO6",
  ], // THORSMORK
  prod_NXELwjYdBql3A4: [
    "DkbHyoS4R7uTZM8nu57hw",
    "5hJI0awZuCYKbjHGFAVsV4",
    "281N0mvRYUohvDTcy8Nu4C",
    "tT2qrr4QavbLJqdYWYZTA",
    "9WWGW4aiDls9wdZulREKy",
  ], // Saping de Noel
  prod_Kvg9l68vxRo1bB: [
    "4wFyhUvNCupTRO2hUs2WBm",
    "1txP95wEwVgaJ19PjyDpY7",
    "4V139v9Ik6q1Uugt7J5DrU",
    "4bLA4MfU080URRdmXIFmmc",
    "364LT3NzoUn7we9Arij1Al",
    "4ocNcTZwpyukYPD9igkW1u",
  ],
}
