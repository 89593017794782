import { getAssetUrl } from "lib/contentful"
import { getProductImages } from "lib/ids"
import React, { useCallback, useEffect } from "react"
import { useState } from "react"
import { ProductDescription } from "./Product"
import Slider from "infinite-react-carousel"

const settings = {
  // adaptiveHeight: true,
  // autoplay: true,
  // centerMode: true,
  duration: 300,
  shift: 100,
  autoplaySpeed: 7000,
}

const SafariSlider = ({ product }) => {
  const [imagesUrl, setimagesUrl] = useState()
  const fetchimagesUrl = useCallback(async () => {
    const images = getProductImages(product.id)

    const assetUrls = await images?.reduce(async (accPromise, id) => {
      const acc = await accPromise
      const assetUrl = await getAssetUrl(id)
      return [...acc, assetUrl]
    }, Promise.resolve([]))

    setimagesUrl(assetUrls)
  }, [product])
  useEffect(() => fetchimagesUrl(), [fetchimagesUrl])

  return (
    !!imagesUrl && (
      <Slider {...settings}>
        {imagesUrl.map((url, i) => (
          <div key={i} className="container">
            <img
              key={i}
              src={url}
              alt="Product Assets"
              height="auto"
              width="100%"
            />
            <div className="overlay">
              <ProductDescription
                dangerouslySetInnerHTML={{
                  __html: product.description, // TODO ADD DESCRIPTION
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    )
  )
}

export default SafariSlider
