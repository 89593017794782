import { isMobile } from "react-device-detect";

const PrincipalImageDTO = (entry) => ({
    title: entry.fields.title,
    subtitle: entry.fields.subtitle,
    imageUrl: getVideoUrl(entry.fields)
  }
)

const getVideoUrl = contentBlock => {
  const media = isMobile ? contentBlock.imageMobile : contentBlock.image
  return formatUrl(media.fields.file.url)
}

const formatUrl = url => `https:${url}`

export default PrincipalImageDTO