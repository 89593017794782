import { getAssetUrl } from "lib/contentful";
import { APP_LOGO } from "lib/ids";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const StyledLogo = styled.img`
  width: 150px;
  top: 30px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 100px;
  }
`;

const Logo = () => {
  const history = useHistory();

  const [logoUrl, setLogoUrl] = useState()
  const fetchLogoUrl = useCallback(async () => setLogoUrl(await getAssetUrl(APP_LOGO)), [])

  useEffect(() => {
    fetchLogoUrl()
  }, [fetchLogoUrl])

  return (
    <StyledLogo
      src={logoUrl}
      alt="Logo"
      onClick={() => history.push("/")}
    />
  );
};

export default Logo;
