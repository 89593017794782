export const yesNoOptions = [
  {
    label: "Oui",
    value: true,
  },
  {
    label: "Non",
    value: false,
  },
];
