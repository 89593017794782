import { getAssetUrl } from "lib/contentful"
import { getProductFirstImageId } from "lib/ids"
import React, { useCallback, useEffect } from "react"
import { useState } from "react"

const SafariImage = ({ product_id }) => {
  const [imageUrl, setImageUrl] = useState()
  const fetchImageUrl = useCallback(async () => {
    const imageId = getProductFirstImageId(product_id)
    const assetUrl = await getAssetUrl(imageId)

    setImageUrl(assetUrl)
  }, [product_id])
  useEffect(() => fetchImageUrl(), [fetchImageUrl])

  return <img alt="A product of the cart" width="100px" src={imageUrl} />
}

export default SafariImage
